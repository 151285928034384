<template>
	<v-container id="error-view" class="fill-height text-center" tag="section">
		<v-row justify="center" align="center" class="fill-height">
			<v-col cols="12" sm="8" md="8" lg="6" xl="4">
				<v-card elevation="0" class="pa-5 bg-fade">
					<v-card-title class="text-h4">
						<v-img alt="Rewind Logo" class="shrink mr-2" contain src="../../assets/logo_small.png" transition="scale-transition" width="40"/>
						rewind
					</v-card-title>
					<v-card-text>
						<v-form ref="form" lazy-validation>
							<v-text-field v-model="password" :rules="passwordRules" label="new password" required type="password" persistent-placeholder></v-text-field>
							<v-text-field v-model="password_confirmation" :rules="passwordRules.concat(passwordConfirmationRule)" label="confirm new password" required type="password" persistent-placeholder></v-text-field>
							<v-card-actions color="transparent">
								<v-btn text rounded :to="{name:'Login'}">back</v-btn>
								<v-spacer></v-spacer>
								<v-btn @click="reset" rounded color="accent">reset</v-btn>
							</v-card-actions>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import {get} from "vuex-pathify";
import {toast} from '@/utils/helpers'

export default {
	name: 'ResetPassword',
	metaInfo: {
		title: 'Reset password',
	},
	props: ['token'],
	computed: {
		passwordConfirmationRule() {
			return () => (this.password === this.password_confirmation) || 'Password must match'
		},
	},
	data: () => ({
		password: '',
		password_confirmation: '',
		passwordRules: [
			v => !!v || 'Password is required',
			v => v.length >= 8 || 'Password must be at least 8 characters',
		],
		passwordConfirmationRules: [
			v => !!v || 'Password is required',
			v => v.length >= 8 || 'Password must be at least 8 characters',
		],
	}),
	methods: {
		reset() {
			if (this.$refs.form.validate()) {
				this.$api.reset_password.update({token: this.token, password: this.password})
					.then(response => {
						toast.add('password reset successfully', 'success')
						this.$router.push({name: 'Login'})
					})
					.catch(error => {
						toast.add(error.message, 'error')
						console.log(error.response.status);
					})
			}
		}
	}
}
</script>